<template>
    <div>
        <AlertModal ref="alert" @closed="successfulBuy" />
        <LoadingModal ref="loading" />
        <ConfirmationModal ref="confirmation" @confirm="buy" />
        <v-progress-linear v-if="isLoading" indeterminate/>
        <v-container v-else>
            <v-row>
                <v-col cols="12" sm="12" md="6" :class="$vuetify.breakpoint.mdAndDown ? 'pl-5' : ''">
                    <v-breadcrumbs class="pl-0 pb-1" :items="breadcrumbs">
                        <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
                    </v-breadcrumbs>

                    <div class="font-weight-bold section-title">{{ $t('views.Prizes.title_product') }}</div>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                    <div class="primary--text text-right d-flex align-end justify-end">
                        <div @click="$router.go(-1)" class="pt-4 mr-2 clickable">{{ $t('views.Prizes.back_to_list') }}</div>
                    </div>
                </v-col>
            </v-row>

            <v-card elevation="0">
                <v-card-text>
                    <v-row>
                        <v-col v-if="prize.photo_url" cols="12" sm="12" md="6">
                            <v-img  :src="prize.photo_url" max-height="500px"></v-img>
                        </v-col>
                        <v-col v-else class="d-flex justify-center align-center media-placeholder grey lighten-2 card-placeholder">
                            <v-icon large>fas fa-image</v-icon>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" class="d-flex flex-column align-content-space-between flex-wrap">

                            <v-col class="px-0"> 
                                <v-card-title class="px-3 card-title">{{ prize.name }}</v-card-title>
                                <v-card-subtitle class="px-3">
                                    <span v-if="prize.expires_in" class="prize-expires-in">{{ $t('views.Prizes.tabs.list.prize_card.expires_in', { expires_in: prize.expires_in }) }}</span>
                                    <span v-else class="body-1">{{ $t('views.Prizes.tabs.list.prize_card.never_expires') }}</span>
                                </v-card-subtitle>
                                
                                <v-col>
                                    <div class="d-inline-block primary--text headline font-weight-bold prize-value px-3 py-1 mt-auto" 
                                        :class="$vuetify.breakpoint.mdAndUp ? 'prize-value-md':''">{{ prize.value | currency }}</div>
                                </v-col>
                            </v-col>

                            <v-col class="mt-10 pb-0 mb-n2">
                                <v-col class="px-0">
                                    <p class="primary_font--text body-1 font-weight-medium">{{ $t('views.Prizes.details.description') }}</p>
                                    <div>{{ prize.description }}</div>
                                </v-col>
                                <v-card-actions class="px-0">
                                    <v-btn @click="confirmBuy(prize)" class="mt-4 px-8 mb-3" color="primary" elevation="0">
                                        {{ $t('views.Prizes.details.trade') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import LoadingModal         from '@/components/modals/LoadingModal'
import AlertModal           from '@/components/modals/AlertModal'
import Prize                from '@/models/Prize'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'PrizesDetailsView',
    components: { AlertModal, LoadingModal, ConfirmationModal },
    mixins: [ HasErrorHandlerMixin ],
    data: (vm) => {
        return {
            prizeId: null,
            prize: null,
            isLoading: false,
            breadcrumbs: [
                { text: vm.$t('drawer.dashboard'), to: { name: 'home' } },
                { text: vm.$t('drawer.prizes'), disabled:false, to: { name: 'prizes' }, exact: true},
                { text: vm.$t('drawer.product') , disabled: true },
            ],
        }
    },
    async created() {
        this.prizeId = this.$route.params.id
        await this.fetchData()
    },
    methods: {
        confirmBuy(props) {
            this.prize = new Prize(props)
            this.$refs.confirmation.showConfirmation(this.$t('modals.ConfirmationModal.text'))
        }, 
        async fetchData() {
            this.isLoading = true

            let response = await Prize.get(this.prizeId)

            if(!response) {
                await this.$refs.alert.showError(this.$t('views.Prizes.error'))
                return
            }

            this.prize = response
            this.isLoading = false
        },
        async buy() {            
            this.$refs.loading.open()

            let errType = ''
            let response = await this.prize.buy()
                .catch(err => {
                    if (err && err.response && err.response.data) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'buy_error'
                })

            if (!response || !response.data || !response.data.length || errType) {
                this.$refs.loading.close()
                errType = ['buy_error', 'insufficient_funds'].includes(errType) ? errType : 'buy_error'
                await this.$refs.alert.showError(this.$t(`views.Prizes.tabs.list.alerts.${errType || 'buy_error'}`))
                return
            }

            this.$refs.loading.close()
            await this.$refs.alert.showSuccess(this.$t('views.Prizes.tabs.list.alerts.buy_success'))
        },
        successfulBuy() {
            this.$router.push({ name: 'prizes'})
        }
    },
}
</script>

<style lang="scss">
.prize-value {
    height: 43px;
    border: 1px solid $input-background-color;
    border-radius: 17px;
    height: auto;
    text-align: center;
}

prize-value-md {
    margin-bottom: 150px;
}
</style>