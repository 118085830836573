var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AlertModal", { ref: "alert", on: { closed: _vm.successfulBuy } }),
      _c("LoadingModal", { ref: "loading" }),
      _c("ConfirmationModal", {
        ref: "confirmation",
        on: { confirm: _vm.buy }
      }),
      _vm.isLoading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndDown ? "pl-5" : "",
                      attrs: { cols: "12", sm: "12", md: "6" }
                    },
                    [
                      _c(
                        "v-breadcrumbs",
                        {
                          staticClass: "pl-0 pb-1",
                          attrs: { items: _vm.breadcrumbs }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "breadcrumbs-chevro",
                              attrs: { slot: "divider" },
                              slot: "divider"
                            },
                            [_vm._v("fas fa-chevron-right")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "font-weight-bold section-title" },
                        [_vm._v(_vm._s(_vm.$t("views.Prizes.title_product")))]
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "primary--text text-right d-flex align-end justify-end"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pt-4 mr-2 clickable",
                            on: {
                              click: function($event) {
                                return _vm.$router.go(-1)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("views.Prizes.back_to_list")))]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card",
                { attrs: { elevation: "0" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _vm.prize.photo_url
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "6" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      src: _vm.prize.photo_url,
                                      "max-height": "500px"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center media-placeholder grey lighten-2 card-placeholder"
                                },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("fas fa-image")
                                  ])
                                ],
                                1
                              ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex flex-column align-content-space-between flex-wrap",
                              attrs: { cols: "12", sm: "12", md: "6" }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "px-0" },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "px-3 card-title" },
                                    [_vm._v(_vm._s(_vm.prize.name))]
                                  ),
                                  _c(
                                    "v-card-subtitle",
                                    { staticClass: "px-3" },
                                    [
                                      _vm.prize.expires_in
                                        ? _c(
                                            "span",
                                            { staticClass: "prize-expires-in" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.Prizes.tabs.list.prize_card.expires_in",
                                                    {
                                                      expires_in:
                                                        _vm.prize.expires_in
                                                    }
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.Prizes.tabs.list.prize_card.never_expires"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                    ]
                                  ),
                                  _c("v-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-block primary--text headline font-weight-bold prize-value px-3 py-1 mt-auto",
                                        class: _vm.$vuetify.breakpoint.mdAndUp
                                          ? "prize-value-md"
                                          : ""
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(_vm.prize.value)
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "mt-10 pb-0 mb-n2" },
                                [
                                  _c("v-col", { staticClass: "px-0" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "primary_font--text body-1 font-weight-medium"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "views.Prizes.details.description"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.prize.description))
                                    ])
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-4 px-8 mb-3",
                                          attrs: {
                                            color: "primary",
                                            elevation: "0"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.confirmBuy(_vm.prize)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.Prizes.details.trade"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }